import { createApp } from 'vue'
import { Divider, Popup, Overlay,Dialog , Loading, CountDown, Collapse, CollapseItem, Field, Icon, Button, Toast, Skeleton, NoticeBar, Swipe, SwipeItem, Step, Steps, RadioGroup, Radio } from 'vant'
import App from './App.vue'
// import store from './store'
import router from './router'
import 'lib-flexible/flexible'
import 'vant/lib/index.css'; // 全局引入样式
import './common/style/base.less';
import { addListener, launch } from 'devtools-detector'

//防爬
antiCreep()

//设置环境变量
if (process.env.VUE_APP_MODE == 'production') {
    window.sign_systemUrl_isprd = 'prd'
} else {
    window.sign_systemUrl_isprd = 'dev'
}

if (window.sign_systemUrl_isprd == 'prd') { //生产
    // window.baseUrl = 'https://dragonpayment.net/api'; //生产
    window.baseUrl = '/api'; //生产
} else {
    window.baseUrl = '/api'; //测试
}

const app = createApp(App) // 创建实例
// 全局过滤器

app.use(Divider)
    .use(Popup)
    .use(Overlay)
    .use(Loading)
    .use(Toast)
    .use(Field)
    .use(Icon)
    .use(Button)
    .use(Skeleton)
    .use(Collapse)
    .use(CollapseItem)
    .use(CountDown)
    .use(NoticeBar)
    .use(Swipe)
    .use(SwipeItem)
    .use(Step)
    .use(Steps)
    .use(RadioGroup)
    .use(Radio)
    .use(Dialog)
    ;

app.use(router)

app.mount('#app')

function antiCreep() {
    // setInterval(() => {
    //     debugger
    // }, 1000)

    //F12/检查/开发工具 禁用
    //【第一种】 debugger
    // const check = function () {
    //     function doCheck(a) {
    //         if (("" + a / a)["length"] !== 1 || a % 20 === 0) {
    //             (function () { }
    //             ["constructor"]("debugger")())
    //         } else {
    //             (function () { }
    //             ["constructor"]("debugger")())
    //         }
    //         doCheck(++a)
    //     }
    //     try {
    //         doCheck(0)
    //     } catch (err) { }
    // };
    // check(); 

    // 【第二种】禁用右键 （防止右键查看源代码）  
    // window.oncontextmenu = function () { return false; };

    //【第三种】在本网页的任何键盘敲击事件都是无效操作 （防止F12和shift+ctrl+i调起开发者工具）  
    // window.onkeydown = window.onkeyup = window.onkeypress = function () {
    //     window.event.returnValue = false;
    //     return false;
    // };

    //监听是否打开开发者工具
    // addListener((isOpen) => {
    //     if (isOpen) {
    //         window.location.href = `https://google.com/`
    //     } else {
    //         // window.isDebug = false;
    //     }

    // });
    // launch();
}
